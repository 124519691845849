import React, { FormEvent, ReactElement, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { LoadableButton } from '@mo/loadable-button';
import { NEW_TO_SCHEME_NAME } from '../routes';
import { Page } from '../../../components/page/Page';
import styles from './precheckPage.module.scss';
import { FormError } from '../../../common/formValidation';
import { externalLinkClicked, inPageNavigation } from '../../../common/googleAnalytics/events';
import { InfoCard } from '../../../components/infoCard/InfoCard';
import CarSide from './assets/carSide.svg';
import Listing from './assets/listing.svg';
import Scooter from './assets/scooter.svg';
import { fetchDigitalAccountUrl } from '../../../gateways/digitalAccountGateway';

interface PrecheckPageProps {
  readonly onSuccessfulSubmit: (precheckConfirmed: boolean) => void;
}

export function PrecheckPage({ onSuccessfulSubmit }: PrecheckPageProps): ReactElement {
  const history = useHistory();
  const [validationErrors] = useState<ReadonlyArray<FormError>>([]);
  const handleSubmit = (): void => {
    onSuccessfulSubmit(true);
    history.push(NEW_TO_SCHEME_NAME);
  };

  return (
    <Page
      title='Check if the online account is ready for you'
      validationErrors={validationErrors}
      withSecurityPromise={false}
    >
      <div className={styles.imageDiv}>
        <img className={styles.precheckImage} alt='' />
      </div>
      <PageContentNewToScheme onSubmit={handleSubmit} />
    </Page>
  );
}

interface PageContentNewToSchemeProps {
  onSubmit: () => void;
}

function PageContentNewToScheme({ onSubmit }: PageContentNewToSchemeProps): ReactElement {
  function handleSubmit(evt: FormEvent): void {
    evt.preventDefault();
    onSubmit();
  }

  const { data } = fetchDigitalAccountUrl();

  return (
    <>
      <form onSubmit={handleSubmit} className={styles.precheckForm}>
        <h2 className={styles.questionHeading}> You can have an account if you:</h2>
        <ul className={styles.unavailable}>
          <li>already have a car, powered wheelchair or scooter on the Scheme</li>
          <li>have recently applied for your first car at the dealer</li>
          <li>
            are looking to apply for a car for the first time and have an{' '}
            <a
              rel='noreferrer'
              target={'_blank'}
              href={'https://www.motability.co.uk/how-it-works/allowances/'}
              onClick={(): void => {
                externalLinkClicked('Eligible mobility allowance');
              }}
            >
              eligible mobility allowance
            </a>
          </li>
          <li>have been appointed to manage an eligible allowance for someone else (an individual appointee)</li>
        </ul>
        <LoadableButton
          loading={false}
          notLoadingText='Create an online account'
          className={styles.startCreateButton}
        />
        <div className={styles.signInLink}>
          <a
            href={data?.url}
            onClick={(): void => {
              inPageNavigation('Sign in if you already have an account');
            }}
          >
            Sign in to your account
          </a>
        </div>
      </form>
      <div className={styles.unavailableInformation}>
        <h2 className={styles.questionHeading}> The account is not available if you’re: </h2>
        <ul className={styles.unavailable}>
          <li>a corporate appointee</li>
          <li>an appointee for more than one allowance recipient</li>
          <li>applying for a powered wheelchair or scooter for the first time</li>
        </ul>

        <p className={styles.infoHeaderText}>
          <strong>You can still:</strong>
        </p>
        <InfoCard
          infoHeader='Search for products'
          infoMessage='View all our powered wheelchairs and scooters with the product search tool.'
          icon={Scooter}
          linkUrl='https://www.motability.co.uk/find-a-vehicle/?utm_source=scheme-website&utm_medium=link&utm_campaign=check-account-ready'
        />
        <InfoCard
          infoHeader='Find a dealer'
          infoMessage='Discuss your needs, arrange a test drive or place an application.'
          icon={Listing}
          linkUrl='https://www.motability.co.uk/whats-available/find-a-dealer/?utm_source=scheme-website&utm_medium=link&utm_campaign=check-account-ready'
        />
        <InfoCard
          infoHeader='Look for suitable cars'
          infoMessage='Match with suitable cars using our car suitability guide.'
          icon={CarSide}
          linkUrl='https://www.motability.co.uk/find-a-vehicle/cars/?utm_source=scheme-website&utm_medium=link&utm_campaign=check-account-ready#search'
        />
      </div>
    </>
  );
}
